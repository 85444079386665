<template>
  <div class="outer">
    <SearchPanel
      show-btn
      size="mini"
      flex
      @getList="getListChange"
    >
      <div>
        <p>联系人</p>
        <el-input
          v-model.trim="applyName"
          clearable
          placeholder="请输入联系人"
          size="mini"
          @clear="getListChange"
        ></el-input>
      </div>
      <div>
        <p>企业名称</p>
        <el-input
          v-model.trim="supplierName"
          clearable
          placeholder="请输入企业名称"
          size="mini"
          @clear="getListChange"
        ></el-input>
      </div>
      <div>
        <p>店铺名称</p>
        <el-input
          v-model.trim="storeName"
          clearable
          placeholder="请输入店铺名称"
          size="mini"
          @clear="getListChange"
        ></el-input>
      </div>
      <div>
        <p>电话</p>
        <el-input
          v-model.trim="mobile"
          clearable
          placeholder="请输入电话"
          size="mini"
          @clear="getListChange"
        ></el-input>
      </div>
    </SearchPanel>

    <el-tabs v-model="status" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" :name="'all'"></el-tab-pane>
      <el-tab-pane label="待审核" :name="'0'"></el-tab-pane>
      <el-tab-pane label="审核通过" :name="'1'"></el-tab-pane>
      <el-tab-pane label="审核拒绝" :name="'2'"></el-tab-pane>
      <el-tab-pane label="已取消" :name="'3'"></el-tab-pane>
    </el-tabs>

    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      border
      :header-cell-style="{
        background: '#D9DDE1',
        color: '#0D0202',
        fontWeight: 400
      }"
      element-loading-text="拼命加载中"
      style="width: 100%"
    >
      <el-table-column
        label="序号"
        prop="id"
        width="70px"
      ></el-table-column>
      <el-table-column
        label="申请企业"
        prop="supplierName"
      ></el-table-column>
      <el-table-column
        label="联系人"
        prop="applyName"
      ></el-table-column>
      <el-table-column
        label="联系电话"
        prop="mobile"
      ></el-table-column>
      <el-table-column
        label="认证时间"
        prop="updateTime"
      >
        <template slot-scope="{row}">
          {{ dayjs(row.updateTime).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
      >
        <template slot-scope="{row}">
          <span v-if="row.status === 0">待审核</span>
          <span v-if="row.status === 1" style="color: green">审核通过</span>
          <span v-if="row.status === 2" style="color: red">审核拒绝</span>
          <span v-if="row.status === 3">已取消</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
      >
        <template slot-scope="{row}">
          <el-link type="primary" :underline="false" @click="operation('show', row)">查看</el-link>
          <template v-if="row.status === 1">
            <el-divider direction="vertical"></el-divider>
            <el-link type="primary" :underline="false" @click="operation('edit', row)">编辑</el-link>
          </template>
          <template v-if="row.status === 0">
            <el-divider direction="vertical"></el-divider>
            <el-link type="primary" :underline="false" @click="operation('auth', row)">审核</el-link>
          </template>
          <template v-if="row.status === 1">
            <el-divider direction="vertical"></el-divider>
            <el-link type="danger" :underline="false" @click="operation('cancel', row)">取消</el-link>
          </template>
          <template v-if="row.status === 2 || row.status === 3">
            <el-divider direction="vertical"></el-divider>
            <el-link type="danger" :underline="false" @click="operation('delete', row)">删除</el-link>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      :current-page="pagination.currentPage"
      :page-sizes="[3,10,20,30,50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import rememberPageList from '@/utils/rememberPageList.js';
import dayjs from 'dayjs';
export default {
  components: {

  },
  data() {
    return {
      applyName: '',
      supplierName: '',
      storeName: '',
      mobile: '',
      status: 'all',
      loading: false,
      tableData: [],
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  watch: {
  },
  created() {
    rememberPageList(this);
  },
  mounted() {
    this.getList();
  },
  methods: {
    dayjs,
    getListChange() {
      this.pagination.currentPage = 1
      this.getList()
    },
    handleClick() {
      this.getListChange();
    },
    getList() {
      this.loading = true
      this.$axios
        .post(this.$api.brand_certification.page, {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          applyName: this.applyName,
          supplierName: this.supplierName,
          storeName: this.storeName,
          mobile: this.mobile,
          status: this.status === 'all' ? '' : this.status
        })
        .then((res) => {
          if (res.code === 0 && res.data) {
            this.tableData = res.data.records
            this.pagination.count = Number(res.data.total)
          } else {
            this.tableData = []
          }
          this.loading = false
        })
    },
    operation(type, row) {
      if (type === 'delete') {
        this.$confirm('是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios
            .get(this.$api.brand_certification.remove, {
              params: {
                id: row.id
              }
            })
            .then((res) => {
              if (res.code === 0) {
                this.getList();
              }
            })
        }).catch(() => {
        });
      } else if (type === 'cancel') {
        this.$confirm('是否确认取消审核？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios
            .post(this.$api.brand_certification.operate_status, {
              id: row.id,
              status: 3
            })
            .then((res) => {
              if (res.code === 0) {
                this.getList();
              }
            })
        }).catch(() => {
        });
      } else {
        rememberPageList(this, ['applyName', 'supplierName', 'storeName', 'mobile', 'status', 'pagination']);
        this.$router.push({
          path: '/supplier/brandAuth/detail',
          query: {
            id: row.id,
            type
          }
        })
      }
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    }
  }
}
</script>
<style lang="scss">
.myImg .el-icon-circle-close {
  color: #fff;
}
</style>
